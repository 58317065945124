.logo-container {
    grid-area: logo;
    animation: logo-spin infinite 5s linear;
}

.slow {
  animation-play-state: paused;
}

.fast {
  animation-play-state: running;
}

.logo {
  animation: logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

@keyframes logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }