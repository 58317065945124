.containerr {
  padding: 2rem;
}

.profilePic {
  border-radius: 5px;
  min-width: 350px;
  min-height: 350px;
  max-width: 0;
  max-height: 0;
}

.profilePicBorder {
  border-radius: 5px;
  border: 1px solid rgba(173, 173, 173, 0.7);
  box-shadow: rgba(193, 195, 210, 0.85) 0px 0px 10px 5px;
  background-color: rgba(255, 255, 255, 0.8);
  min-width: 350px;
  min-height: 350px;
  max-width: 0;
  max-height: 0;
}

.resumeBG {
  border: 2px solid rgb(13, 83, 206);
  box-shadow: rgba(193, 195, 210, 0.88) 0px 0px 15px 10px;
  background: white;
}

.divColH {
  border-right: 5px solid rgba(13, 83, 206, 0.25);
  margin-top: 5px;
}

.divColV {
  border-top: 5px solid rgba(13, 83, 206, 0.25);
}

.divColVMain {
  border-right: 3px solid rgb(13, 83, 206);
  border-left: 3px solid rgb(13, 83, 206);
  border-bottom: 3px solid rgb(13, 83, 206);
}

.ResumePrint {
  width: 100%;
  height: 4rem;
  font-size: 20px;
}

.contactFormBg {
  border: 3px solid rgb(13, 83, 206);
  box-shadow: rgba(193, 195, 210, 0.88) 0px 0px 15px 10px;
  background: rgba(255, 255, 255, 0.68);
}
