.Rheader {
  background: #0d53ce;
  width: 100%;
  height: 100%x;
}
.RheaderPic {
  border-radius: 20rem;
  width: 200px;
  height: 200px;
}

.ResumeSkillsSkill {
  margin: 10px;
  background: #0d53ce;
  color: white;
  padding: 5px 10px 2px 10px;
  text-align: center;
  vertical-align: middle;
  border-radius: 10rem;
  border: 1px solid rgba(59, 112, 226, 0.7);
  box-shadow: rgba(141, 167, 220, 0.7) 0px 0px 10px 3px;
}

.customHRDiv {
  border-top: 2px solid rgba(59, 112, 226, 0.7);
  margin-top: 3px;
}

.clickMeProject:hover {
  font-size: 20px;
}
